import React from 'react'
import './styles/about.css'
import './styles/machine.css'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { SRLWrapper } from "simple-react-lightbox";
const images = [
  "https://imgs-app.netlify.app/img/bellota/img/machine/01.jpeg",
  "https://imgs-app.netlify.app/img/bellota/img/machine/02.jpeg",
  "https://imgs-app.netlify.app/img/bellota/img/machine/03.jpeg",
  "https://imgs-app.netlify.app/img/bellota/img/machine/4.png",
  "https://imgs-app.netlify.app/img/bellota/img/machine/6.png",
  "https://imgs-app.netlify.app/img/bellota/img/machine/7.png",
  "https://imgs-app.netlify.app/img/bellota/img/machine/8.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/machine/9.jpeg",
  "https://imgs-app.netlify.app/img/bellota/img/machine/10.jpeg",
  "https://imgs-app.netlify.app/img/bellota/img/machine/11.jpeg",
  "https://imgs-app.netlify.app/img/bellota/img/machine/12.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/machine/5.png",
]

 const Machine = () => {
  return (
    <>
      <div className="content_about">
        
        <div className="about-header">
          <div className="about_overlay">
          <div className="about_container">
              <p className="about_title">Venta de <strong>Máquinas</strong></p>
            </div>
          </div>
        </div>
        <div className="machine_information">
          <div className="row_machine">
            <p className="machine_info">
            Importamos maquinaria para la rectificación de motores livianos y pesados. Nos especializamos en la comercialización de equipos de procedencia europea.
            <br /><br />
              En un contexto de desarrollo tecnológico de la industria automotriz con motores de alto rendimiento y que dejan poco margen para los errores es necesario realizar su reparación con equipos adecuados para alcanzar los parámetros de calidad de fábrica.
              <br /><br />
              Ponemos a su alcance los equipos necesarios para realizar su servicio de rectificado con la calidad europea y a precios altamente competitivos.

            </p>
          </div>
          <div className="row_machine">
          <SRLWrapper>
          <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}} 
            >
                <Masonry gutter="10px">
               
                    {images.map((image, i) => (
                      <div className="wapperimg">

                      
                        <img
                            key={i}
                            src={image}
                            style={{width: "100%", display: "block"}}
                            alt=""
                        />
                       </div>
                    ))}
                     
                </Masonry>
            </ResponsiveMasonry>
            </SRLWrapper>
          </div>
          
        </div>
      </div>
    </>
  )
}

export default Machine;