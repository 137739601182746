import React  from 'react'
import './styles/home.css'
import Slider from "react-slick";
/* import AppContext from "../context/AppContext"; */
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay } from 'swiper';
import { Link } from 'react-router-dom'
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]);

const autos = [
  "https://imgs-app.netlify.app/img/bellota/img/autos/1.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/autos/2.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/autos/3.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/autos/4.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/autos/5.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/autos/6.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/autos/7.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/autos/8.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/autos/9.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/autos/10.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/autos/11.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/autos/12.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/autos/13.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/autos/14.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/autos/16.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/autos/17.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/autos/18.jpg",

]

 const Home = () => {
/*   const {state} =useContext(AppContext)
  const { clients } = state; */
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 7000,
  };

  return (
    <>

      <div className="content_home">
        <div className="Home_container">
        <div className="swiper_content_app">
          <Slider {...settings} className="pimer" >
              <div className="sliderReact_1">
                <div className="overlay">
                    <div className="img_content">
                        
                        <p className="title_overlay_two animate__animated  animate__fadeInUp">
                        ¿Cómo identifico que mi motor necesita ser reparado?
                        </p>
                        <div className="content_li">
                          <p className="info_slider_two animate__animated animate__fadeInUp animate__delay-1se"><i class="far fa-check-circle"></i> Tu auto no prende en frío</p>
                          <p className="info_slider_two animate__animated animate__fadeInUp animate__delay-2se"><i class="far fa-check-circle"></i> Lenta respuesta de aceleración </p>
                          <p className="info_slider_two animate__animated animate__fadeInUp animate__delay-3se"><i class="far fa-check-circle"></i> Consumo excesivo de combustible y refrigerante</p>
                          <p className="info_slider_two animate__animated animate__fadeInUp animate__delay-4se"><i class="far fa-check-circle"></i> Alto consumo de aceite </p>
                          <p className="info_slider_two animate__animated animate__fadeInUp animate__delay-5se"><i class="far fa-check-circle"></i> No pasas revisión técnica por emisión de gases </p>
                          <p className="info_slider_two animate__animated animate__fadeInUp animate__delay-6se"><i class="far fa-check-circle"></i> Tu motor está descompresionado! </p>
                        </div>
                        <Link to="/contacto" className="button_header animate__animated two animate__fadeInUp animate__delay-7se" >
                          <button type="button">
                          Contáctanos
                          </button>
                        </Link>
                      </div>
                    </div>
                </div
                >
              <div
                className="sliderReact_2">
                      <div className="overlay">
                    <div className="img_content">
                        <p className="title_type">
                          Servicios
                        </p>
                        <p className="title_overlay">
                        Reparación general de motores
                        </p>
                        <Link to="/contacto" className="button_header" >
                          <button type="button">
                          Contáctanos
                          </button>
                        </Link>
                      </div>
                    </div>
                </div>
              <div className="sliderReact_3">
                    <div className="overlay">
                  <div className="img_content">
                      <p className="title_type">
                        Servicios
                      </p>
                      <p className="title_overlay">
                      Reparación integral de culatas
                      </p>
                      <Link to="/contacto" className="button_header" >
                        <button type="button">
                        Contáctanos
                        </button>
                      </Link>
                    </div>
                  </div>
              </div>
            </Slider>
          </div>
        <div className="home_clients_content">
            <Swiper
              spaceBetween={10}
              slidesPerView={2}
              navigation
              loop={true}
              breakpoints={{
                1228: {
                  slidesPerView: 6,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                960: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                600: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                500: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
              }}
              autoplay= {{
                delay: 1500,
                disableOnInteraction: false,
              }}
              /* pagination={{ clickable: true }} */
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
              className="full_tw"
            >
               {autos.map((auto, i) => (
              <SwiperSlide className="swiper_clients">
                    <div className="img_home_client_sli">
                      <img src={auto} alt=""/>
                  </div>
              </SwiperSlide>
              ))}
            </Swiper>
            </div>

          
        </div>
      </div>
      
     
    </>
  )
}

export default Home;