import React, {  useState }from 'react'

import "./styles/tab.css";

function Tabs() {

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };




  return (
    <div className="container">
      <div className="bloc-tabs">
        <button
          className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(1)}
        >
          La Victoria
        </button>
        <button
          className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(2)}
        >
          Huachipa
        </button>
        
      </div>

      <div className="content-tabs">
        <div
          className={toggleState === 1 ? "content  active-content" : "content"}
        >
         <div className="map_google">
        
        <iframe className="content_map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15606.604556621729!2d-77.0220194!3d-12.0675071!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x303391e9faa6c18c!2sRectificaciones%20Generales%20Bellota%20Srl!5e0!3m2!1ses-419!2spe!4v1622877813938!5m2!1ses-419!2spe" width="100%" height="280" frameborder="0"  allowfullscreen="" aria-hidden="false" title="google-map"></iframe>
        </div>
          
         
        </div>

        <div
          className={toggleState === 2 ? "content  active-content" : "content"}
        >
          <div className="map_google">
         
        <iframe className="content_map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31220.591518856832!2d-76.94408619463225!3d-12.003955528478237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c41216b0eb45%3A0x6d5bbd09caf4e944!2sLos%20Huertos%20De%20Huachipa!5e0!3m2!1ses-419!2spe!4v1622893049607!5m2!1ses-419!2spe" width="100%" height="280" frameborder="0"  allowfullscreen="" aria-hidden="false" title="google-map"></iframe>
        </div>
    
          
        </div>
      </div>
    </div>
  );
}

export default Tabs;