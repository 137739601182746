import React from 'react'
import './styles/about.css'
import './styles/works.css'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { SRLWrapper } from "simple-react-lightbox";

const images = [
  "https://imgs-app.netlify.app/img/bellota/img/work/13.jpg",
/*   "https://imgs-app.netlify.app/img/bellota/img/work/10.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/work/1.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/work/2.jpg", */
  
  "https://imgs-app.netlify.app/img/bellota/img/work/8.png",
  "https://imgs-app.netlify.app/img/bellota/img/work/6.png",
  "https://imgs-app.netlify.app/img/bellota/img/work/9.png",
  "https://imgs-app.netlify.app/img/bellota/img/work/5.png",
  "https://imgs-app.netlify.app/img/bellota/img/work/7.png",
  "https://imgs-app.netlify.app/img/bellota/img/work/3.png",
  "https://imgs-app.netlify.app/img/bellota/img/work/4.jpg",

  "https://imgs-app.netlify.app/img/bellota/img/work/11.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/work/12.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/work/15.jpeg",
  "https://imgs-app.netlify.app/img/bellota/img/work/14.jpeg",
]


 const Works = () => {


  return (
    <>
      <div className="content_about">
        
        <div className="about-header">
          <div className="about_overlay">
          <div className="about_container">
              <p className="about_title">Nuestros <strong>Trabajos</strong></p>
            </div>
          </div>
        </div>
        <div className="about_information">
          <div className="row_works">
          <SRLWrapper>
          <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}} 
            >
                <Masonry gutter="10px">
               
                    {images.map((image, i) => (
                      <div className="wapperimg">

                      
                        <img
                            key={i}
                            src={image}
                            style={{width: "100%", display: "block"}}
                            alt=""
                        />
                       </div>
                    ))}
                     
                </Masonry>
            </ResponsiveMasonry>
            </SRLWrapper>
          </div>
        </div>
      </div>
    </>
  )
}

export default Works;