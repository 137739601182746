// eslint-disable-next-line import/no-anonymous-default-export
export default {
  services: [
    {
      "id":1,
      "name":"client1",
      'img':"https://imgs-app.netlify.app/img/bellota/img/about/01.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://imgs-app.netlify.app/img/bellota/img/about/02.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://imgs-app.netlify.app/img/bellota/img/about/03.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://imgs-app.netlify.app/img/bellota/img/about/04.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://imgs-app.netlify.app/img/bellota/img/about/05.jpg"
    },
  ],
  clients: [
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/1.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/13.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/3.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/4.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/5.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/6.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/7.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/8.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/9.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/10.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/11.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/12.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/2.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/14.jpg"
    },

   
  ],
  autos: [
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/1.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/2.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/3.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/4.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/5.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/6.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/7.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/8.jpg"
    },
    {
      "id":1,
      "name":"client1",
      'img':"https://apiweb.netlify.app/images/bellota/clients/9.jpg"
    },
   
  ]
};