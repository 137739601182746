import React, { useState } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import './styles/navbar.css';
/* import Dropdown from './Dropdown';
import DropdownMobile from './DropdownMobile'; */
import logo from './../images/logo.png'

function Navbar() {
  const [click, setClick] = useState(false);
 /*  const [clickSer, setClickSer] = useState(false);
  const [dropdown, setDropdown] = useState(false); */

  const handleClick = () => setClick(!click);
/*   const handleClickSer = () => setClickSer(!clickSer); */

  const closeMobileMenu = () => setClick(false);

  /* const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  }; */


  return (
    <>
      <nav className='navbar'>
        <div className="navbar__content">
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          <img src={logo} alt="" />
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <NavLink to='/' exact="/" className='nav-links' activeClassName="current"  onClick={closeMobileMenu}>
              Home
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink to='/nosotros'  className='nav-links' activeClassName="current"  onClick={closeMobileMenu}>
              Nosotros
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink to='/trabajos'  className='nav-links' activeClassName="current"  onClick={closeMobileMenu}>
              Trabajos
            </NavLink>
          </li>
         {/*  <li
            className='nav-item service'
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <span>La empresa <i className='fas fa-caret-down' /></span>
            
            {dropdown && <Dropdown />}
          </li> */}
          {/* <li
            className='nav-item serviceMobile'
            onClick={handleClickSer}
          >
            <span>La empresa <i className='fas fa-caret-down' /></span>  
            <div className={clickSer ? 'drop' : 'dropDisplay'}
            onClick={closeMobileMenu}
            >
            <DropdownMobile />
            </div>
          </li> */}
          <li className='nav-item'>
            <NavLink
              to='/servicios'
              className='nav-links'
              onClick={closeMobileMenu}
              activeClassName="current"
            >
            Servicios
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink activeClassName="current" to='/maquinas' className='nav-links' onClick={closeMobileMenu}>
              Venta de Máquinas
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/contacto'
              className='nav-links-mobile'
              onClick={closeMobileMenu}
            >
              Contacto
            </NavLink>
          </li>
          <Button />
        </ul>
        </div>
      </nav>

    </>
  );
}

export default Navbar;