import React from 'react';
import './styles/footer.css';
import './styles/Footertwo.scss';
/* import { Link } from "react-router-dom"; */
import logoblack from '../images/logo-gris.png';
import { Link } from 'react-router-dom';
const Footer = () => {
	return (
		<>
			<div className="footer_container">
				<div className="footer-content">
					<div className="footer-content-box">
						<div className="footer-box-item">
							<div className="img-logo-footer">
								<img src={logoblack} alt="" />
							</div>
							<p>
								<strong>Sede La Victoria</strong>
								<br />
								Jr Abtao 881
							</p>
							<p>
								<strong>Sede Huachipa</strong>
								<br />
								Mz C lote 4 Urb. Huertos de Nieveria
							</p>
							<p>
								<strong>Horario de Atención</strong>
								<br />
								Lunes a viernes de 8:30 am a 6:00 pm
								<br />
								Sábados de 8:30 am a 1:00 pm
							</p>
						</div>
						<div className="footer-content-box-item">
							<p>
								<Link to="/nosotros">Nosotros</Link>
								<br />
								<Link to="/servicios">Servicios</Link>
								<br />
								<Link to="/contacto">Contacto</Link>
							</p>
							<div className="footer-redes">
								<p className="title_redes">Síguenos</p>
								<div className="content_redes">
									<a
										href="https://www.facebook.com/Rectificaciones-generales-Bellota-srl-535369856670455"
										target="_blank"
										rel="noreferrer"
									>
										<div>
											<i className="fab fa-facebook-square"></i>
										</div>
									</a>
									<a
										href="https://www.instagram.com/rectificacionesbellota/"
										target="_blank"
										rel="noreferrer"
									>
										<div className="">
											<i className="fab fa-instagram"></i>
										</div>
									</a>
								</div>
								<p className="title_redes">
									<a
										href="https://www.digiltek.com/"
										target="_blank"
										rel="noreferrer"
									>
										Diseñado y desarrollado por Digiltek – Leonardo Ramos
									</a>
								</p>
							</div>
						</div>
						<div className="footer-content-box-item">
							<p>
								{' '}
								<i class="fab fa-whatsapp"></i> +51 941 166 537
							</p>
							<div className="footer_pay">
								<span>
									<i class="fab fa-cc-visa"></i>
								</span>

								<span>
									<i class="fab fa-cc-visa"></i>
								</span>

								<span>
									<i class="fab fa-cc-amex"></i>
								</span>
							</div>
							<div className="map_google">
								<iframe
									className="content_map"
									src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15606.604556621729!2d-77.0220194!3d-12.0675071!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x303391e9faa6c18c!2sRectificaciones%20Generales%20Bellota%20Srl!5e0!3m2!1ses-419!2spe!4v1622877813938!5m2!1ses-419!2spe"
									width="100%"
									height="180"
									frameborder="0"
									allowfullscreen=""
									aria-hidden="false"
									title="google-map"
								></iframe>
							</div>
						</div>
					</div>
					<div className="footer-content-copy"></div>
				</div>
			</div>
		</>
	);
};

export default Footer;
