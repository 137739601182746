import React,{ useState, useContext }  from 'react'
import './styles/about.css'
import { Link } from 'react-router-dom'

import AppContext from "./../context/AppContext";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
SwiperCore.use([Autoplay]);

 const About = () => {

  const {state} =useContext(AppContext)
  const { services } = state;
  const [index, setIndex] = useState(0)


  return (
    <>
      <div className="content_about">
        
        <div className="about-header">
          <div className="about_overlay">
          <div className="about_container">
              <p className="about_title">Nuestra <strong>Historia</strong></p>

              <div className="link_contact">
              <Link to="/contacto" className="" >
                <button className="contact_link" type="button">
                Contáctanos
                </button>
              </Link>
              </div>
             
            </div>
          </div>
        </div>
        <div className="about_information">
          <div className="row_about">
            <p>
            Rectificaciones Generales Bellota es una empresa con más de 40 años de experiencia y especializada en <strong> RECTIFICACIÓN DE MOTORES DIESEL, GAS y GASOLINEROS</strong>, así como fabricación de piezas en general, soldadura, reconstrucción de monoblock, culatas, cigüeñales, etc.
                Nuestras instalaciones poseen tecnología de punta e instrumental necesario para brindar a nuestros clientes un servicio basado en los más altos estándares de calidad.
                <br /><br />
                Contamos con los conocimientos indicados para atender las necesidades de un mercado en constante evolución. 
                <br /><br />
                Prestamos nuestros servicios a talleres y concesionarios de vehículos,
                Nuestros procesos son totalmente eficientes:
                Trabajamos   con manuales y medidas de fábrica  
                Contamos con maquinaria y herramienta de medición de alta precisión.

            </p>
          </div>
          <div className="about_swiper mobile">
          <div className="row_slider">
          <Swiper
                spaceBetween={20}
                slidesPerView={2}
                navigation
               /*  pagination={{ clickable: true }}
                autoplay={{ delay: 2500, disableOnInteraction: false }} */
                loop={true}
                breakpoints={{
                  1228: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  960: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  600: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  500: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
                /* scrollbar={{ draggable: true }} */
                className="serviceone_img_slider"
              /*  onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)} */
              >
                {services.map((imagets, index) => (
                  <SwiperSlide key={index} className="slidertwo">
                    <div className="about_slider-content">
                      
                      <img 
                        key={index} 
                        onClick={() => setIndex(index)}
                        src={imagets.img} 
                        alt=""/>
                      
                    
                    </div>
                  </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
          <div className="row_about">
            <div className="img_about">
              <img src={services[index].img} alt=""/>
            </div>
          </div>
          
        </div>
        <div className="about_swiper desktop">
          <div className="row_slider">
          <Swiper
                spaceBetween={20}
                slidesPerView={2}
                navigation
               /*  pagination={{ clickable: true }}
                autoplay={{ delay: 2500, disableOnInteraction: false }} */
                loop={true}
                breakpoints={{
                  1228: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  960: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  600: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  500: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
                /* scrollbar={{ draggable: true }} */
                className="serviceone_img_slider"
              /*  onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)} */
              >
                {services.map((imagets, index) => (
                  <SwiperSlide key={index} className="slidertwo">
                    <div className="about_slider-content">
                      
                      <img 
                        key={index} 
                        onClick={() => setIndex(index)}
                        src={imagets.img} 
                        alt=""/>
                      
                    
                    </div>
                  </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  )
}

export default About;