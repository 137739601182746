import React ,  { useContext }from 'react'
import './styles/about.css'
import './styles/services.css'
import AppContext from "../context/AppContext";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { SRLWrapper } from "simple-react-lightbox";



const images = [
  "https://imgs-app.netlify.app/img/bellota/img/services/01.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/services/02.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/services/03.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/services/4.jpeg",
  "https://imgs-app.netlify.app/img/bellota/img/services/6.jpeg",
  "https://imgs-app.netlify.app/img/bellota/img/services/7.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/services/8.jpg",
  "https://imgs-app.netlify.app/img/bellota/img/services/9.jpeg",
  "https://imgs-app.netlify.app/img/bellota/img/services/10.jpeg",
  "https://imgs-app.netlify.app/img/bellota/img/services/5.jpg",
 
]

 const Services = () => {
  const {state} =useContext(AppContext)
  const { clients } = state;
  return (
    <>
      <div className="content_about">
        
        <div className="about-header">
          <div className="about_overlay">
          <div className="about_container">
              <p className="about_title">Nuestros <strong>Servicios</strong></p>
            </div>
          </div>
        </div>
        <div className="services_information">
          <div className="row_services">
            <div className="services_valores">
              <div className="services_valores-content">
                <div className="valores"><i class="far fa-check-circle"></i> Rectificación de motores</div>
                <div className="valores"><i class="far fa-check-circle"></i> Prueba hidrostática</div>
                <div className="valores"><i class="far fa-check-circle"></i> Encamisetado</div>
                <div className="valores"><i class="far fa-check-circle"></i> Prueba magnaflux </div>
                <div className="valores"><i class="far fa-check-circle"></i> Rectificación de todos los componentes </div>
                <div className="valores"><i class="far fa-check-circle"></i> Soldadura en componentes de aluminio y hierro fundido </div>
                <div className="valores"><i class="far fa-check-circle"></i> Lavado ultrasonido </div>
                <div className="valores"><i class="far fa-check-circle"></i> Venta de repuestos</div>
              </div>
            </div>
          </div>
        </div>
        <div className="section_clients">
          <p className="title_clients">Nuestros principales clientes</p>
          <div className="home_clients">
               {clients.map((imgs, i) => (
              
                    <div key={i} className="img_home_clients">
                      <img src={imgs.img} alt=""/>
                  </div>
              ))}
            </div>
          </div>
          <div className="row_services_img">
          <SRLWrapper>
          <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}} 
            >
                <Masonry gutter="10px">
                    {images.map((image, i) => (
                      <div className="wapperimg">
                        <img
                            key={i}
                            src={image}
                            style={{width: "100%", display: "block"}}
                            alt=""
                        />
                       </div>
                    ))}
                     
                </Masonry>
            </ResponsiveMasonry>
            </SRLWrapper>
          </div>
      </div>
    </>
  )
}

export default Services;