import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
/* import { Router } from '@reach/router' */
import Home from  '../pages/Home'
import About from  '../pages/About'
import Works from  '../pages/Works'
import Machine from  '../pages/Machine'
import Services from  '../pages/Services'
import NotFound from  '../pages/NotFound'
import Contact from  '../pages/Contact'
import Layout from '../pages/Layout';
import AppContext from '../context/AppContext'
import useInitialState from '../hooks/useInitialState'
import 'animate.css/animate.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ScrollTop from '../components/ScrollTop';

function App ()  {
  const initialState = useInitialState()

  return (
    <BrowserRouter>
      <ScrollTop/>
      <AppContext.Provider value={initialState}>
          
          <Switch>
            <Layout>
            <Route exact path="/" component={Home} />
            <Route  exact path="/nosotros" component={About} />
            <Route  exact path="/trabajos" component={Works} />
            <Route  exact path="/maquinas" component={Machine} />
            <Route  exact path="/servicios" component={Services}  />
            <Route  exact path="/contacto"  component={Contact} />
            </Layout>
            
            <Route component={NotFound} />
        </Switch>
        
      </AppContext.Provider>
    </BrowserRouter>
  );
}

export default App;
